import create from "zustand";

import { getNotesArray } from "../features/notes/NoteFunctions";
import { decrypt } from "../utils/crypto";

// WHATEVER GETS ADDED TO THE STORE NEEDS TO GET ADDED TO THE BUILD OBJECT
const useCurrentCustomerStore = create((set) => ({
	ID: null,
	firstName: "",
	lastName: "",
	middleName: "",
	gender: "",
	generation: "",
	SSN: "",
	birthDate: "",
	age: "",
	email: "",
	cellPhone: "",
	extraPhone: "",
	homePhone: "",
	workPhone: "",
	license: "",
	licExpires: "",
	licState: "",
	isLoading: true,
	street: "",
	unit: "",
	city: "",
	state: "",
	zip: "",
	county: "",
	fromDate: "",
	toDate: "",
	ownerShip: "",
	years: "",
	months: "",
	mounthlyPmt: "",
	creditScore: "",
	creditScoreEfx: "",
	creditScoreXpn: "",
	source: "",
	mailRecNum: null,
	customerNumber: "",
	llCity: "",
	llName: "",
	llPhone: "",
	llState: "",
	llStreet: "",
	llUnit: "",
	llZip: "",
	customerStatus: 0,
	originalID: "",
	status: "",
	military: "",
	type: 0,
	licType: "",
	licCountry: "USA",
	uploadCode: "",
	createdOn: null,

	notes: [],
	addressHist: [],
	empHist: [],
	insurance: {},
	references: [],
	currentAddress: {},
	mailingAddress: {},
	credit700Hist: [],
	liabilities: [],

	editStreet: (val) => set(() => ({ street: val })),
	editUnit: (val) => set(() => ({ unit: val })),
	editCity: (val) => set(() => ({ city: val })),
	editState: (val) => set(() => ({ state: val })),
	editZip: (val) => set(() => ({ zip: val })),
	editCounty: (val) => set(() => ({ county: val })),
	editFromDate: (val) => set(() => ({ fromDate: val })),
	editToDate: (val) => set(() => ({ toDate: val })),
	editOwnerShip: (val) => set(() => ({ ownerShip: val })),
	editYears: (val) => set(() => ({ years: val })),
	editMonths: (val) => set(() => ({ months: val })),
	editMounthlyPmt: (val) => set(() => ({ mounthlyPmt: val })),
	editID: (val) => set(() => ({ ID: val })),
	editFirstName: (val) => set(() => ({ firstName: val })),
	editLastName: (val) => set(() => ({ lastName: val })),
	editSSN: (val) => set(() => ({ SSN: val })),
	editBirthDate: (val) => set(() => ({ birthDate: val })),
	editEmail: (val) => set(() => ({ email: val })),
	editExtraPhone: (val) => set(() => ({ extraPhone: val })),
	editHomePhone: (val) => set(() => ({ homePhone: val })),
	editWorkPhone: (val) => set(() => ({ workPhone: val })),
	editLicense: (val) => set(() => ({ license: val })),
	editLicExpires: (val) => set(() => ({ licExpires: val })),
	editLicState: (val) => set(() => ({ licState: val })),
	editIsLoadingTrue: () => set(() => ({ isLoading: true })),
	editIsLoadingFalse: () => set(() => ({ isLoading: false })),
	editMiddleName: (val) => set(() => ({ middleName: val })),
	editGender: (val) => set(() => ({ gender: val })),
	editGeneration: (val) => set(() => ({ generation: val })),
	editAge: (val) => set(() => ({ age: val })),
	editCellPhone: (val) => set(() => ({ cellPhone: val })),
	editCreditScore: (val) => set(() => ({ creditScore: val })),
	editCreditScoreEfx: (val) => set(() => ({ creditScoreEfx: val })),
	editCreditScoreXpn: (val) => set(() => ({ creditScoreXpn: val })),
	editSource: (val) => set(() => ({ source: val })),
	editMailRecNum: (val) => set(() => ({ mailRecNum: val })),
	editCustomerNumber: (val) => set(() => ({ customerNumber: val })),
	editLlCity: (val) => set(() => ({ llCity: val })),
	editLlName: (val) => set(() => ({ llName: val })),
	editLlPhone: (val) => set(() => ({ llPhone: val })),
	editLlState: (val) => set(() => ({ llState: val })),
	editLlStreet: (val) => set(() => ({ llStreet: val })),
	editLlUnit: (val) => set(() => ({ llUnit: val })),
	editLlZip: (val) => set(() => ({ llZip: val })),
	editCustomerStatus: (val) => set(() => ({ customerStatus: val })),
	editOriginalID: (val) => set(() => ({ originalID: val })),
	editStatus: (val) => set(() => ({ status: val })),
	editMilitary: (val) => set(() => ({ military: val })),
	editType: (val) => set(() => ({ type: val })),
	editLicType: (val) => set(() => ({ licType: val })),
	editLicCountry: (val) => set(() => ({ licCountry: val })),
	editUploadCode: (val) => set(() => ({ uploadCode: val })),
	editCreatedOn: (val) => set(() => ({ createdOn: val })),

	editNotes: (val) => set(() => ({ notes: val })),
	editAddressHist: (val) => set(() => ({ addressHist: val })),
	editEmpHist: (val) => set(() => ({ empHist: val })),
	editInsurance: (val) => set(() => ({ insurance: val })),
	editInsuranceValue: (key, val) =>
		set((state) => ({
			insurance: {
				...state.insurance,
				[key]: val,
			},
		})),
	editCredit700Hist: (val) => set(() => ({ credit700Hist: val })),
	editReferences: (val) => set(() => ({ references: val })),
	editCurrentAddress: (val) => set(() => ({ currentAddress: val })),
	editMailingAddress: (val) => set(() => ({ mailingAddress: val })),
	editLiabilities: (val) => set(() => ({ liabilities: val })),

	setCustomerFields: (customer) =>
		set({
			ID: customer.ID,
			firstName: customer.firstName,
			lastName: customer.lastName,
			SSN: decrypt(customer.SSN),
			birthDate: customer.birthDate,
			email: customer.email,
			extraPhone: customer.extraPhone,
			homePhone: customer.homePhone,
			workPhone: customer.workPhone,
			license: customer.license,
			licExpires: customer.licExpires,
			licState: customer.licState,
			middleName: customer.middleName,
			gender: customer.gender,
			generation: customer.generation,
			age: customer.age,
			cellPhone: customer.cellPhone,
			ownerShip: customer.ownerShip,
			years: customer.years,
			months: customer.months,
			mounthlyPmt: customer.mounthlyPmt,
			creditScore: customer.creditScore,
			creditScoreEfx: customer.creditScoreEfx,
			creditScoreXpn: customer.creditScoreXpn,
			source: customer.source,
			mailRecNum: customer.mailRecNum,
			customerNumber: customer.customerNumber,
			llCity: customer.llCity,
			llName: customer.llName,
			llPhone: customer.llPhone,
			llState: customer.llState,
			llStreet: customer.llStreet,
			llUnit: customer.llUnit,
			llZip: customer.llZip,
			customerStatus: customer.customerStatus,
			originalID: customer.originalID,
			military: customer.military,
			type: customer.type,
			notes: getNotesArray(customer.notes),
			status: customer.status,
			licType: customer.licType,
			licCountry: customer.licCountry,
			uploadCode: customer.uploadCode,
			createdOn: customer.createdOn,
		}),

	reset: () =>
		set({
			ID: null,
			firstName: "",
			lastName: "",
			SSN: "",
			birthDate: "",
			email: "",
			extraPhone: "",
			homePhone: "",
			workPhone: "",
			license: "",
			licExpires: "",
			licState: "",
			isLoading: true,
			middleName: "",
			gender: "",
			generation: "",
			age: "",
			cellPhone: "",
			street: "",
			unit: "",
			city: "",
			state: "",
			zip: "",
			county: "",
			fromDate: "",
			toDate: "",
			ownerShip: "",
			years: "",
			months: "",
			mounthlyPmt: "",
			credit700Hist: [],
			creditScore: "",
			creditScoreEfx: "",
			creditScoreXpn: "",
			source: "",
			mailRecNum: null,
			customerNumber: "",
			llCity: "",
			llName: "",
			llPhone: "",
			llState: "",
			llStreet: "",
			llUnit: "",
			llZip: "",
			notes: [],
			addressHist: [],
			empHist: [],
			insurance: {},
			references: [],
			currentAddress: {},
			mailingAddress: {},
			customerStatus: 0,
			originalID: "",
			status: "",
			military: "",
			type: 0,
			licType: "In-State Driver's License",
			licCountry: "USA",
			uploadCode: "",
		}),
}));
export default useCurrentCustomerStore;
