import React, { useEffect, useState } from "react";
import Camera from "assets/img/placeholder.png";
import Loading from "components/loadingSpinner/Loading";
import ChildrenModal from "components/modals/ChildrenModal";
import InventoryTable from "components/tables/InventoryTable";
import DealTemplateModal from "features/settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import useRoute from "hooks/useRoute";
import { isMobile } from "react-device-detect";
import useCurrentDealStore from "stores/DealStore";
import useCurrentVehicleStore from "stores/InventoryStore";
import history from "utils/createBrowserHistory";
import {
	getVehicleDetails,
	getVehicleListByDateRange,
	removeVehicle,
	updateStatus,
} from "api/InventoryAPI";
import { PATHS } from "constants/Constants";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useFromUser } from "stores/LocalStorageHelper";
import {
	confirmDeleteMessage,
	deleteFailAlert,
	moveFailAlert,
	retrieveFailAlert,
	successAlertCustom,
} from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";
import useDateRangeStore from "stores/DateRangeStore";
import ViewToggle from "components/ViewToggle";
import InventoryCardList from "./InventoryCardList";

/**
 * InventoryListData component to fetch and display a list of inventory items.
 *
 * @param {Object} props - Component props
 * @param {Array} props.statusList - The list of statuses to filter inventory items by
 * @returns {JSX.Element} The rendered component
 */
const InventoryListData = ({ statusList }) => {
	const { fromDate, toDate } = useDateRangeStore();
	const location = useLocation();
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const inventoryDetail = useRoute(PATHS.INVENTORY_DETAIL);
	const homeRoute = useRoute(PATHS.DASHBOARD);
	const newRoute = useRoute(PATHS.INVENTORY_LIST);

	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [templateModal, setTemplateModal] = useState(false);
	const [vehicle, setVehicle] = useState({});
	const [view, setView] = useState("table");
	const [focusSearch, setFocusSearch] = useState(false);

	const toggleTemplate = () => setTemplateModal(!templateModal);

	const { editThumbnail, editInventoryID } = useCurrentVehicleStore(
		(state) => ({
			editThumbnail: state.editThumbnail,
			editInventoryID: state.editInventoryID,
		}),
		shallow
	);

	const { editCarRecNum } = useCurrentDealStore(
		(state) => ({
			editCarRecNum: state.editCarRecNum,
		}),
		shallow
	);

	/**
	 * Handles accessing the details of a specific inventory item.
	 *
	 * @param {number} inventoryID - The ID of the inventory item
	 * @param {string} thumbnail - The thumbnail URL of the inventory item
	 */
	const accessDetailsHandler = (inventoryID, thumbnail) => {
		editThumbnail(thumbnail);
		editInventoryID(inventoryID);

		const params = new URLSearchParams(location.search);
		const queryParamState = decodeURIComponent(params.get("state"));
		const queryParamCode = decodeURIComponent(params.get("code"));

		if (queryParamCode && queryParamState) {
			history.push(
				`${inventoryDetail}?code=${encodeURIComponent(
					queryParamCode
				)}&state=${encodeURIComponent(queryParamState)}`,
				{ ID: inventoryID }
			);
		} else {
			history.push(inventoryDetail, { ID: inventoryID });
		}
	};

	/**
	 * Handles removing a vehicle from the inventory.
	 *
	 * @param {number} inventoryID - The ID of the vehicle to remove
	 */
	const RemoveVehicle = (inventoryID) => {
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			if (res === "Yes, Delete it") {
				removeVehicle(inventoryID).then(
					() => {
						history.push(newRoute);
						successAlertCustom("Your vehicle removed from your inventory!");
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, deleteFailAlert);
						}
					}
				);
			}
		});
	};
	const MoveVehicle = (inventoryID) => {
		console.log("Moving vehicle to inventory");
		updateStatus(inventoryID, "Available").then(
			() => {
				const newData = data.filter((item) => item.inventoryID !== inventoryID);
				setData(newData);
				successAlertCustom("Your vehicle moved to inventory!");
				// history.push(newRoute);
			},
			(err) => {
				if (!err.isGeneralError) {
					console.log(err);
					showApiError(err, moveFailAlert);
				}
			}
		);
	};

	/**
	 * Handles clicking on a row in the inventory table.
	 *
	 * @param {Object} rowData - The data of the clicked row
	 */
	const handleRowClick = (rowData) => {
		if (rowData.inventoryID) {
			accessDetailsHandler(rowData.inventoryID, rowData.thumbnailURL);
		}
	};

	/**
	 * Formats the data for display in the table.
	 *
	 * @param {Array} content - The raw data content
	 * @returns {Array} The formatted data
	 */
	const dataFormat = (content) => {
		return Object.keys(content).map((key) => {
			const vehicle = content[key];
			const today = dayjs();
			const start = dayjs(vehicle.dateIn);
			const end = vehicle.dateOut ? dayjs(vehicle.dateOut) : today;
			//			const diff = end.diff(start, "day");
			const diff = start.diff(end, "day");

			return {
				thumbnail: (
					<img
						className="zoom"
						alt="vehicle"
						src={`${vehicle.thumbnail}?lastmod=${Date.now()}`}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
					/>
				),
				thumbnailURL: vehicle.thumbnail,
				inventoryID: vehicle.inventoryID,
				license: vehicle.license,
				stockAndVin: `${dayjs(vehicle.dateIn).format("YYYY/MM/DD")} ${
					vehicle.stockNo
				}`,
				stockNo: vehicle.stockNo,
				vehicle: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
				vehicleSearch: `${vehicle.make} ${vehicle.model} ${vehicle.year} ${vehicle.vin} ${vehicle.license}`,
				askingPrice: `$${parseFloat(vehicle.price)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
				vin: vehicle.vin,
				status: vehicle.vehicleStatus,
				type: vehicle.type,
				age: diff,
				start: start.format("MM/DD/YYYY"),
				end: vehicle.dateOut ? end.format("MM/DD/YYYY") : "",
				Actions: (
					<>
						<div
							className="zoom btn-sm btn btn-primary"
							onClick={(e) => {
								e.stopPropagation();
								accessDetailsHandler(vehicle.inventoryID, vehicle.thumbnail);
							}}
						>
							<i className="icon-big text-center nc-icon nc-paper text-default" />
							&nbsp;View
						</div>
						{statusList === "Available,Pending" && (
							<div
								className="zoom btn-sm btn btn-primary"
								onClick={(e) => {
									e.stopPropagation();
									getVehicleData(vehicle.inventoryID, vehicle.thumbnail);
								}}
								readOnly={
									vehicle.vehicleStatus !== "Pending" &&
									vehicle.vehicleStatus !== "Available"
								}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>
								<span> Deal</span>
							</div>
						)}
						{statusList === "Trade,Hold,Deleted" &&
							vehicle.status === "deleted" && (
								<div
									className="btn btn-danger btn-sm"
									onClick={(e) => {
										e.stopPropagation();
										RemoveVehicle(vehicle.inventoryID);
									}}
								>
									<i className="icon-big text-center nc-icon nc-simple-remove"></i>
									&nbsp;Remove
								</div>
							)}
						{statusList === "Appraisal" && (
							<div
								className="btn btn-primary btn-sm"
								onClick={(e) => {
									e.stopPropagation();
									MoveVehicle(vehicle.inventoryID);
								}}
							>
								<i className="icon-big text-center nc-icon "></i>
								Move to Inventory
							</div>
						)}
					</>
				),
			};
		});
	};

	/**
	 * Fetches the list data based on the status list.
	 *
	 * @param {Array} statusList - The list of statuses to filter by
	 */
	const getListData = (statusList) => {
		if (dealerID && locationID) {
			getVehicleListByDateRange(locationID, statusList, fromDate, toDate).then(
				(response) => {
					const formattedData = dataFormat(response.data.content);
					setData(formattedData);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};

	/**
	 * Fetches the details of a specific vehicle.
	 *
	 * @param {number} inventoryID - The ID of the vehicle to fetch
	 */
	const getVehicleData = (inventoryID) => {
		getVehicleDetails(inventoryID).then(
			(res) => {
				setVehicle(res.data.content);
				editCarRecNum(res.data.content.inventoryID);
				editThumbnail(res.data.content.thumbnail);
				toggleTemplate();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(homeRoute);
				}
			}
		);
	};

	const handleViewToggle = (newView) => {
		setView(newView);
		if (newView === "card") {
			setFocusSearch(true);
		}
	};

	useEffect(() => {
		if (focusSearch) {
			// Reset focusSearch after it's been used
			setFocusSearch(false);
		}
	}, [focusSearch]);

	useEffect(() => {
		setLoadingList(true);
		getListData(statusList);
		// eslint-disable-next-line
	}, [statusList, fromDate, toDate]);

	return (
		<div style={{ minHeight: "1000px" }}>
			{loadingList ? (
				<Loading containerStyle={{ height: "80vh" }} />
			) : (
				<>
					{!isMobile && <ViewToggle view={view} onToggle={handleViewToggle} />}
					{isMobile || view === "card" ? (
						<InventoryCardList
							data={data}
							onEdit={accessDetailsHandler}
							onDeal={getVehicleData}
							focusSearch={focusSearch}
						/>
					) : (
						<InventoryTable data={data} onRowClick={handleRowClick} />
					)}
				</>
			)}
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal newVehicle={vehicle} />
			</ChildrenModal>
		</div>
	);
};

export default InventoryListData;
