import React, { useEffect } from "react";
import ChildrenModal from "components/modals/ChildrenModal";
import { DealStatus, vehicleTypesOBJ } from "constants/Constants";
import formNavigationUtils from "utils/formNavigationUtils";
import { Card, CardBody, CardHeader, CardTitle, Form, Row } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import shallow from "zustand/shallow";
import LabelAndInput from "../../LabelAndInput";
import DMVFeesTable from "./DMVFeesTable";
import DMVPriceSection from "./DMVPriceSection";
import {
	useFromLocations,
	useFromUser,
} from "../../../../../../stores/LocalStorageHelper";
import dayjs from "dayjs";

// DMV fees calculations are done here
const DMVModal = ({ modal, toggle }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const stateLocation = useFromLocations("state");
	// Deal store
	const {
		type,
		dealID,
		miscDMVFees,
		editMiscDMVFees,
		vehicleYear,
		buyerState,
		vehicleType,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			dealID: state.deal.ID,
			miscDMVFees: state.miscDMVFees,
			editMiscDMVFees: state.editMiscDMVFees,
			vehicleYear: state.vehicle?.year,
			buyerState: state.buyerAddress?.state,
			vehicleType: state.vehicle?.type,
			calculate: state.calculate,
		}),
		shallow
	);

	// Store values
	const {
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		editTitleFee,
		editFilingFee,
		editSmogExemption,
		editWeightFee,
		editTireFee,
		editBpa,
	} = useCurrentSaleStore(
		(state) => ({
			titleFee: state.titleFee,
			filingFee: state.filingFee,
			smogExemption: state.smogExemption,
			weightFee: state.weightFee,
			tireFee: state.tireFee,
			bpa: state.bpa,
			totalDMVFees: state.totalDMVFees,
			editTitleFee: state.editTitleFee,
			editFilingFee: state.editFilingFee,
			editSmogExemption: state.editSmogExemption,
			editWeightFee: state.editWeightFee,
			editTireFee: state.editTireFee,
			editBpa: state.editBpa,
		}),
		shallow
	);

	// All the editable input fields, labels, and their edit functions
	let fields = [
		{
			name: "dmvTitleFee",
			value: titleFee,
			edit: editTitleFee,
			label: "Title Fee",
		},
		{
			name: "dmvFilingFee",
			value: filingFee,
			edit: editFilingFee,
			label: "Filing Fee",
		},
		{
			name: "dmvSmogExemption",
			value: smogExemption,
			edit: editSmogExemption,
			label: "Smog Exemption",
		},
		{
			name: "dmvWeightFee",
			value: weightFee,
			edit: editWeightFee,
			label: "Weight Fee",
		},
		{
			name: "dmvTireFee",
			value: tireFee,
			edit: editTireFee,
			label: "Tire Fee",
		},
		{
			name: "dmvBpa",
			value: bpa,
			edit: editBpa,
			label: "Vehicle ROS(BPA)",
		},
	];

	const calcPropAssessmentUT = () => {
		if (buyerState !== "UT" || !vehicleYear) {
			return miscDMVFees;
		}

		let year = parseInt(vehicleYear.trim());
		let currentYear = dayjs().year();
		// no vehicle year then no fee
		if (!year) {
			return miscDMVFees;
		}
		let index = miscDMVFees.length;
		let fee = miscDMVFees.find((obj, i) => {
			if (obj.type === "AGE-BASED") {
				index = i;
				return true;
			} else return false;
		});

		console.log(fee);
		if (!fee) {
			return miscDMVFees;
		} else {
			fee = { ...fee };
		}

		let feeAmount = 0;
		const yearDiff = currentYear - year;

		if (vehicleType === vehicleTypesOBJ.MOTORHOME.value) {
			if (yearDiff >= 15) feeAmount = 90;
			else if (yearDiff >= 12) feeAmount = 180;
			else if (yearDiff >= 9) feeAmount = 315;
			else if (yearDiff >= 6) feeAmount = 425;
			else if (yearDiff >= 3) feeAmount = 540;
			else feeAmount = 690;
		} else if (
			vehicleType === vehicleTypesOBJ.RV.value ||
			vehicleType === vehicleTypesOBJ.TRAILER.value
		) {
			if (yearDiff >= 12) feeAmount = 20;
			else if (yearDiff >= 9) feeAmount = 65;
			else if (yearDiff >= 6) feeAmount = 90;
			else if (yearDiff >= 3) feeAmount = 135;
			else feeAmount = 175;
		} else if (vehicleType === vehicleTypesOBJ.CAMPER.value) {
			if (yearDiff >= 12) feeAmount = 10;
			else if (yearDiff >= 9) feeAmount = 25;
			else if (yearDiff >= 6) feeAmount = 35;
			else if (yearDiff >= 3) feeAmount = 50;
			else feeAmount = 70;
		} else if (vehicleType === vehicleTypesOBJ.MOTORCYCLE.value) {
			if (yearDiff >= 12) feeAmount = 10;
			else if (yearDiff >= 9) feeAmount = 35;
			else if (yearDiff >= 6) feeAmount = 50;
			else if (yearDiff >= 3) feeAmount = 70;
			else feeAmount = 95;
		} else {
			if (yearDiff >= 12) feeAmount = 10;
			else if (yearDiff >= 9) feeAmount = 50;
			else if (yearDiff >= 6) feeAmount = 80;
			else if (yearDiff >= 3) feeAmount = 110;
			else feeAmount = 150;
		}
		fee.amount = feeAmount;
		let miscDMVFees_ = [...miscDMVFees];
		miscDMVFees_[index] = fee;
		editMiscDMVFees(miscDMVFees_);
		calculate();
		return miscDMVFees_;
	};

	useEffect(() => {
		console.log("dependency array change");
		console.log({ vehicleYear, buyerState, vehicleType });
		calcPropAssessmentUT();
	}, [vehicleYear, buyerState, vehicleType]);

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="DMV Fees"
			children={
				<Form onKeyDown={formNavigationUtils} className="col">
					<Card>
						<CardHeader>
							<CardTitle tag="h3">Standard Fees</CardTitle>
						</CardHeader>
						<CardBody>
							<DMVPriceSection />
						</CardBody>
					</Card>
					<Card className="mt-3">
						<CardHeader>
							<CardTitle tag="h3">Manage Fees</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								{fields.map((obj, index) => (
									<div key={index} className="col-md-6 mb-2">
										<LabelAndInput
											name={obj.name}
											readOnly={type !== DealStatus.PENDING}
											value={obj.value}
											label={obj.label}
											onChange={obj.edit}
											buttonLabel={true}
											btnClass="btn btn-md btn-primary disabled w-100"
										/>
									</div>
								))}
							</Row>
						</CardBody>
					</Card>
					<DMVFeesTable />
				</Form>
			}
		/>
	);
};

export default DMVModal;
