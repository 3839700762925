import cryptoBrowserify from "crypto-browserify";

export default cryptoBrowserify;

const key = process.env.REACT_APP_ENCRYPTION_KEY;

export const encrypt = (text) => {
	if (!text) return "";
	const iv = cryptoBrowserify.randomBytes(16);
	const cipher = cryptoBrowserify.createCipheriv(
		"aes-256-gcm",
		Buffer.from(key, "hex"),
		iv
	);

	let encrypted = cipher.update(text, "utf8", "base64");
	encrypted += cipher.final("base64");
	const authTag = cipher.getAuthTag();
	return `${iv.toString("base64")}:${encrypted}:${authTag.toString("base64")}`;
};

export const decrypt = (encryptedText) => {
	try {
		const parts = encryptedText.split(":");
		if (parts.length !== 3) {
			throw new Error("Invalid encrypted text format");
		}
		const [iv, encrypted, authTag] = parts.map((part) =>
			Buffer.from(part, "base64")
		);
		const decipher = cryptoBrowserify.createDecipheriv(
			"aes-256-gcm",
			Buffer.from(key, "hex"),
			iv
		);
		decipher.setAuthTag(authTag);
		let decrypted = decipher.update(encrypted, "base64", "utf8");
		decrypted += decipher.final("utf8");
		return decrypted;
	} catch (error) {
		return encryptedText;
	}
};
